// Generated by Framer (44d7d2d)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["taLqzBWG8", "yJ_tC_uXd"];

const serializationHash = "framer-rqDvH"

const variantClassNames = {taLqzBWG8: "framer-v-1coxsua", yJ_tC_uXd: "framer-v-1rntt90"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "taLqzBWG8", "Variant 2": "yJ_tC_uXd"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "taLqzBWG8"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "taLqzBWG8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear1okt4wy = activeVariantCallback(async (...args) => {
await delay(() => setVariant("yJ_tC_uXd"), 1000)
})

const onAppear813q78 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("taLqzBWG8"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear1okt4wy, yJ_tC_uXd: onAppear813q78})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1coxsua", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"taLqzBWG8"} ref={ref ?? ref1} style={{backgroundColor: "rgb(44, 8, 128)", borderBottomRightRadius: 250, borderTopLeftRadius: 250, boxShadow: "0px 0px 3px 3px rgb(255, 175, 46)", ...style}} variants={{yJ_tC_uXd: {backgroundColor: "rgb(255, 175, 46)", boxShadow: "0px 0px 3px 3px rgb(44, 8, 128)"}}} {...addPropertyOverrides({yJ_tC_uXd: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QWxhdHNpLXJlZ3VsYXI=", "--framer-font-family": "\"Alatsi\", \"Alatsi Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Subscribe us</motion.p></React.Fragment>} className={"framer-1a6p381"} fonts={["GF;Alatsi-regular"]} layoutDependency={layoutDependency} layoutId={"G0g6TqpEu"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} variants={{yJ_tC_uXd: {"--extracted-r6o4lv": "rgb(44, 8, 128)"}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({yJ_tC_uXd: {children: <React.Fragment><motion.p style={{"--font-selector": "R0Y7QWxhdHNpLXJlZ3VsYXI=", "--framer-font-family": "\"Alatsi\", \"Alatsi Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(44, 8, 128))"}}>Subscribe us</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-rqDvH.framer-10t4qhv, .framer-rqDvH .framer-10t4qhv { display: block; }", ".framer-rqDvH.framer-1coxsua { height: 40px; position: relative; width: 210px; }", ".framer-rqDvH .framer-1a6p381 { flex: none; height: auto; left: 52%; position: absolute; top: 47%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 210
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"yJ_tC_uXd":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruQy0uGv4k: React.ComponentType<Props> = withCSS(Component, css, "framer-rqDvH") as typeof Component;
export default FrameruQy0uGv4k;

FrameruQy0uGv4k.displayName = "Subscribt";

FrameruQy0uGv4k.defaultProps = {height: 40, width: 210};

addPropertyControls(FrameruQy0uGv4k, {variant: {options: ["taLqzBWG8", "yJ_tC_uXd"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameruQy0uGv4k, [{explicitInter: true, fonts: [{family: "Alatsi", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/alatsi/v13/TK3iWkUJAxQ2nLNGGzUHte5fKg.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})